import React from 'react'

const Education = () => {
  return (
    <>
    <div class="main-container">
  <section id="timeline" class="timeline-outer">
    <div class="container" id="content">
      <div class="row">
        <div class="col s12 m12 l12">
          <h1 class="blue-text lighten-1 header">Education</h1><hr style={{ width: "40%", margin:"0%" }}/>
          <ul class="timeline">
            <li class="event" >
              <div className="event-headings">
              <h3>National Institute of Technology (NIT) Srinagar</h3>
              <h5>Bachelor of Technology - BTech, Electrical Engineering</h5>
              <h6>December 2021 - Present</h6>
              <h6><b>Grade:</b> 7.44</h6>
              </div>
              <p>
              I am currently pursuing a Bachelor's degree in Electrical Engineering at National Institute of Technology, Srinagar. I have completed 4 semesters and have a CGPA of 7.4. I have taken courses in Control Sytems I & II, Electrical Machines I & II, Power Systems, Analoge & Digtal Electronics, Mathematics, Signals and Systems, Analog Comminucation Systems, among others.              </p>
            </li>
            <li class="event" >
            <div className="event-headings">
              <h3>Green Valley Educational Institution</h3>
              <h5>JKBOSE (XII) - HSC Education</h5>
              <h6>Dec 2018 - NOV 2021</h6>
              <h6><b>Grade:</b> 99%</h6>
              </div>
              <p>
              My studies at Green Valley Educational Institution, focusing on non-medical subjects, equipped me with a strong foundation in science and analytical thinking. This dedication manifested in my HSC boards achievement, where I proudly ranked 5th in the state.
              </p>
            </li>
            <li class="event" >
            <div className="event-headings">
              <h3>Linton Hall Secondary School</h3>
              <h5>JKBOSE (X) - SSC Education</h5>
              <h6>Dec 2018</h6>
              <h6><b>Grade:</b> 93%</h6>
              </div>
              <p>
              I completed my Secondary School (SSC) education at Linton Hall Secondary School, consistently earning top marks within the top 10% of my class. I graduated with a remarkable 93% overall average, demonstrating strong academic capabilities and dedication to learning.              </p>
            </li>
           
            <li class="event">
              
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</div>
    </>
  )
}

export default Education