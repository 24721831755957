import React from 'react';
const profileImg = require("./images/profile-img.png")
const Profile = () => {
  return (
    <>
    <div className="profile">
        <div className="profile-content">
            <h3>About Me</h3> <hr style={{ width: "40%", margin:"0%" }}/>
            <div className="profile-greetings">
                <p>I am an Electrical Engineering student at the National Institute of Technology (NIT) Srinagar with a passion for Innovation and Technology.
             <br/> Other then this, I can usually be found Graphic Designing, reading any Philosophical book or 
             just having a break. <br />
             You can read more about me on my <a style={{color:"#fff", fontWeight: '700'}} href="https://www.linkedin.com/in/musharaf-aijaz-baba-1b1540215/">LinkedIn</a> page, or Check my <a style={{color:"#fff", fontWeight: '700'}} href="https://github.com/Musharraffaijaz">Github</a> for some cool things.

            Here are a few technologies I’ve been working with recently:
            <dl>
                <li>FullStack Developemnt</li>
                <li>C++</li>
                <li> Python</li>
                <li>Git</li>
                <li>Jenkins</li>
                <li>Database</li>
            </dl>

                </p>
             <div className="img">
             <img src={profileImg} alt="" />
             </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Profile