import React from 'react'
const brow = require("./images/broww.jpg")
const kart = require("./images/GOKART.jpg")
const RbArm = require("./images/rbArm.png")
const Agri = require("./images/agri.jpg")

const Projects = () => {
  return (
    <>
        <div className="projects-section">
            <div className="project">
                <h2>Some Things I’ve Built</h2>
                <hr style={{ width: "40%", margin:"0%" }}/>
                <div className="projects-container">
                    <div className="project-img">
                    <img src={brow} alt="" />
                    </div>
                    <div className="project-content">
                        <h5>Featured Project</h5>
                        <h1>Brow Browser</h1>
                        <div className="desc">
                            <p>
                            A custom developed Browser that uses and runs through Google's API to search and provids a convenient and efficient solution for browsing.
                            </p>
                        </div>
                        <div className="tech-used">
                            <ol>
                                <li>ReactJS</li>
                                <li>Google API</li>
                                <li>Material UI</li>
                                <li>Figma</li>
                                <li>GIT</li>
                            </ol>
                        </div>
                    </div>

                </div>


{/* 2nd project */}

<div className="projects-container1">
                    <div className="project-img">
                    <img src={Agri} alt="" />
                    </div>
                    <div className="project-content1">
                        {/* <h5>Featured Project</h5> */}
                        <h1>CropDex</h1>
                        <div className="desc">
                            <p>
                            Cropdex is a web application powered by Artificial Intelligence (AI) and Machine Learning (ML) models tailored for the agricultural sector. It leverages these technologies to offer predictive analysis, disease detection in crops                            </p>
                        </div>
                        <div className="tech-used1">
                            <ol>
                                <li>AI</li>
                                <li>ML</li>
                                <li>ReactJS</li>
                                <li>Tensorflow</li>
                                <li>GIT</li>
                            </ol>
                        </div>
                    </div>

                </div>

            {/* 3rd Project */}


                <div className="projects-container">
                    <div className="project-img">
                    <img src={kart} alt="" />
                    </div>
                    <div className="project-content">
                        {/* <h5>Featured Project</h5> */}
                        <h1>Go - Kart</h1>
                        <div className="desc">
                            <p>
                            This involves the design, development, and construction of go-karts, small open-wheel vehicles used primarily for recreational racing or educational purposes.</p>
                        </div>
                        <div className="tech-used">
                            <ol>
                                <li>Power Systems</li>
                                <li>Control Systems</li>
                                <li>Engineering Fundamentals</li>
                                <li>Electrical Machines</li>
                                {/* <li>GIT</li> */}
                            </ol>
                        </div>
                    </div>

                </div>

                {/* 4th project */}

<div className="projects-container1">
                    <div className="project-img">
                    <img src={RbArm} alt="" />
                    </div>
                    <div className="project-content1">
                        {/* <h5>Featured Project</h5> */}
                        <h1 className='final-project'>Robotic Arm</h1>
                        <div className="desc">
                            <p>
                            This Arduino-powered robotic arm is the new kid on the block, ready to revolutionize the art scene. Watch in awe as it twirls and swirls, leaving a trail of vibrant chaos on its canvas. Prepare to be surprised, amused, and maybe a little bit paint-splattered.                            </p>
                        </div>
                        <div className="tech-used1">
                            <ol>
                                <li>Arduino</li>
                                <li>Ras Pi</li>
                                <li>Python</li>
                                <li>C++</li>
                                <li>Embedded Sys</li>
                            </ol>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </>
  )
}

export default Projects