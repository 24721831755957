import React from 'react'

const Footer = () => {
  return (
    <>
       <div className="footer">
        <h3><a href="mailto:eyemusharraffaijaz@gmail.com?subject=Let's Connect&body=Hi%20there,%20I'm%20interested%20in%20your%20work.%20Can%20we%20chat?" >Thanks for visiting.</a></h3>
        </div> 
    </>
  )
}

export default Footer