import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaGithub } from "react-icons/fa";
import { IoLogoBehance } from "react-icons/io5";
import { FaLinkedinIn } from "react-icons/fa";
import { SiLeetcode } from "react-icons/si";
import { FaXTwitter } from "react-icons/fa6";


function MyNavbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <nav className="navbar">
      {/* <a href="/" className="navbar-brand"> <i>Musharaf Aijaz.</i></a> */}
      <button onClick={toggleMenu} className="navbar-toggle">
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
      </button>
      <ul className={`navbar-links ${isOpen ? "open" : ""}`}>
        <li>
          <a href="https://github.com/Musharraffaijaz"><FaGithub style={{fontSize: '20px'}}/></a>
        </li>
        <li>
          <a href="https://www.linkedin.com/in/musharaf-aijaz-baba-1b1540215/"><FaLinkedinIn style={{fontSize: '20px'}}/></a>
        </li>
        <li>
          <a href="https://www.behance.net/musharraffaijaz"><IoLogoBehance style={{fontSize: '20px'}}/></a>
        </li>
        <li>
          <a href="https://leetcode.com/Musharraffaijaz/"><SiLeetcode style={{fontSize: '20px'}}/></a>
        </li>
        <li>
          <a href="https://twitter.com/musharraffaijaz"><FaXTwitter style={{fontSize: '20px'}}/></a>
        </li>
        <li>
          <a href=""><button>Resume</button></a>
        </li>
        
      </ul>
    </nav>
  );
}

export default MyNavbar;
