import React from 'react'

const About = () => {
  return (
    <>
    <div className="about-me">
        <div className="greetings">
            <h5>Hi, my name is</h5>
            <h1>Musharaf Aijaz.</h1>
            <h3>An Electrical Engineering undergrad and a Tech Geek.</h3>
            <div className="about-me-content">
            <p>I am an Electrical Engineering student at the National Institute of Technology (NIT) Srinagar with a passion for Innovation and Technology.
             I have a deep interest in Electrical Engineering, AI/ML, IOT, Embedded Systems. I have a proven ability to work independently and as part of a 
             team to create high-quality, user-friendly work. <br/> Other then this, I can usually be found Graphic Designing, reading any Philosophical book or 
             just having a break. </p>
            </div>
        </div>
    </div>
    </>
  )
}

export default About