import logo from './logo.svg';
import './App.css';
import MyNavbar from './components/MyNavbar';
import About from './components/About';
import Profile from './components/Profile';
import Education from './components/Education';
import Experience from './components/Experience';
import Projects from './components/Projects';
import Footer from './components/Footer';


function App() {
  return (
    <div className="App">
     <MyNavbar/>
     <About/>
     <Profile/>
     <Education/>
     <Experience/>
     <Projects/>
     <Footer/>
    </div>
  );
}

export default App;
