import React from 'react'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
const WorkIcon = require("./profile.jpg")
const SchoolIcon = require("./profile.jpg")
const StarIcon = require("./profile.jpg")

const Experience = () => {
  return (
<>

<div className="main-exp">
    <div className="exp-content">
        <h2>Experience</h2>
        <hr style={{ width: "40%", margin:"0%" }}/>
<div className="exp-timeline">
<VerticalTimeline>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{background: 'transparent', border: '2px solid #fff', color: '#fff', padding: '30px', fontFamily:'Poppins' }}
    contentArrowStyle={{ borderRight: '7px solid  #fff' }}
    iconStyle={{ background: '#4f6982', color: '#192734' }}
    // icon={<WorkIcon />}
  >
    <h3 className="vertical-timeline-element-title">Full Stack Developer</h3>
    <h4 className="vertical-timeline-element-subtitle">Web Cycle</h4>
    
    <p>
      <ul style={{textAlign:'justify'}}>
        <li>Developing and maintaining web applications using React.js and other related technologies.</li>
        <li>Collaborating with cross-functional teams including designers, product managers, and other developers to create high-quality products.</li>
        <li>Participating in code reviews and providing constructive feedback to other developers</li>
      </ul>
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
   className="vertical-timeline-element--work"
   contentStyle={{background: 'transparent', border: '2px solid #fff', color: '#fff', padding: '30px', fontFamily:'Poppins' }}
   contentArrowStyle={{ borderRight: '7px solid  #fff' }}
   iconStyle={{ background: '#4f6982', color: '#192734' }}
   // icon={<WorkIcon />}
 >
   <h3 className="vertical-timeline-element-title">Operations and Maintainance Intern</h3>
   <h4 className="vertical-timeline-element-subtitle">JKPDCL</h4>
   
   <p>
     <ul style={{textAlign:'justify'}}>
       <li>Conducted regular inspections on substation equipment such as transformers, circuit breakers, and switchgear to ensure functionality and identify potential issues.</li>
       <li>Acquired knowledge about equipment utilised in sub-grid stations, such as transformers, switchgear, circuit breakers, and related devices.</li>
     </ul>
   </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
     className="vertical-timeline-element--work"
     contentStyle={{background: 'transparent', border: '2px solid #fff', color: '#fff', padding: '30px', fontFamily:'Poppins' }}
     contentArrowStyle={{ borderRight: '7px solid  #fff' }}
     iconStyle={{ background: '#4f6982', color: '#192734' }}
     // icon={<WorkIcon />}
   >
     <h3 className="vertical-timeline-element-title">Graphics Designer</h3>
     <h4 className="vertical-timeline-element-subtitle">Career Catalysts</h4>
     
     <p>
       <ul style={{textAlign:'justify'}}>
         <li>Conducted rigorous testing of graphical elements and functionalities to identify bugs, glitches, or rendering issues. Collaborated with senior developers to troubleshoot and resolve graphics-related problems.</li>
         <li>Assisted in optimizing graphics pipelines and rendering processes to enhance performance and efficiency.</li>
         <li>Implementing responsive design and ensuring cross-browser compatibility.</li>
       </ul>
     </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
     className="vertical-timeline-element--work"
     contentStyle={{background: 'transparent', border: '2px solid #fff', color: '#fff', padding: '30px', fontFamily:'Poppins' }}
     contentArrowStyle={{ borderRight: '7px solid  #fff' }}
     iconStyle={{ background: '#4f6982', color: '#192734' }}
     // icon={<WorkIcon />}
   >
     <h3 className="vertical-timeline-element-title">Systems Engineer</h3>
     <h4 className="vertical-timeline-element-subtitle">J&K Bank</h4>
     
     <p>
       <ul style={{textAlign:'justify'}}>
         <li> I troubleshoot, maintain, and upgrade everything behind the scenes, ensuring smooth banking operations for millions</li>
         <li>I build and fortify firewalls, monitor for suspicious activity, and respond to potential attacks, keeping J&K Bank's data and customers safe..</li>
         <li>Glitches, crashes, unexpected hiccups – they're all in a day's work for me.</li>
       </ul>
     </p>
  </VerticalTimelineElement>

</VerticalTimeline>
</div>
    </div>
</div>
</>
  )
}

export default Experience